/*------------------------------------*\
    # MIXINS
\*------------------------------------*/

// Global mixins available to the entire project. Define file-specific
// mixins inside the file to which they relate.

// Mixin to create a declaration whose value is a rem unit. Also provide pixel fallback.
//
// .simple-usage {
//   @include rem(padding, 10px);
// }

@mixin rem($property, $value) {
    #{$property}: $value;
    #{$property}: rem($value);
}

// Mixin to drop micro clearfix into a selector.
//
// .simple-usage {
//   @include clearfix();
// }

@mixin clearfix() {
    *zoom: 1;
    clear: both;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

// Generate `:hover` and `:focus` styles in one go.
@mixin hocus() {

    &:hover,
    &:focus {
        @content;
    }
}

// Generate `:hover` and `:focus` underline animation
// Accepts color and height of underline
//
// .simple-usage {
//      @include hocusUnderline($color-white, .1rem);
// }
@mixin hocusUnderline($color: $color-primary, $height: .2rem) {

    &:after {
        content: '';
        background: $color;
        display: block;
        height: $height;
        transition: width .3s $ease-in;
        width: 0;
    }

    @include hocus() {
        text-decoration: none;

        &:after {
            width: 100%;
        }
    }
}

// Generate basic link styles including :hover and :focus
// Accepts color and height of underline
//
// a {
//      @include baseLink($color-white, .1rem);
// }
@mixin baseLink($color: $color-primary, $height: .1rem) {
    border-bottom: .1rem solid $color-primary;
    color: $color-primary;
    transition: padding .3s $ease-in;

    @include hocus() {
        border-color: darken($color-primary, 5%);
        color: darken($color-primary, 5%);
        padding-bottom: .2rem;
        text-decoration: none;
    }
}

// Mixin to add min breakpoints directly to classes
//
// .simple-usage {
//      font-size: 1rem;
//
//      @include responsive(md) {
//          font-size: 1.25rem;
//      }
// }

@mixin responsive($class) {
    @if $class==sm {
        @media (min-width: $screen-sm) {
            @content;
        }
    }

    @else if $class==md {
        @media (min-width: $screen-md) {
            @content;
        }
    }

    @else if $class==lg {
        @media (min-width: $screen-lg) {
            @content;
        }
    }

    @else if $class==xl {
        @media (min-width: $screen-xl) {
            @content;
        }
    }

    @else {
        @warn "Breakpoint mixin supports: sm, md, lg, xl";
    }
}

// Mixin to add max breakpoints directly to classes
//
// .simple-usage {
//      font-size: 1rem;
//
//      @include responsive(md) {
//          font-size: 1.25rem;
//      }
// }

@mixin responsiveMax($class) {
    @if $class==sm {
        @media (max-width: $screen-sm) {
            @content;
        }
    }

    @else if $class==md {
        @media (max-width: $screen-md) {
            @content;
        }
    }

    @else if $class==lg {
        @media (max-width: $screen-lg) {
            @content;
        }
    }

    @else if $class==lg {
        @media (max-width: $screen-xl) {
            @content;
        }
    }

    @else {
        @warn "Breakpoint mixin supports: sm, md, lg, xl";
    }
}

// Outputs pixels as rem
// Returns 1.6rem
//
// .simple-usage {
//      @include font-size(16);
// }

@function rem($size) {
    $rem: calc($size / 10);
    @return #{$rem}#{'rem'};
}

@mixin font-size($value) {
    #{'font-size'}: rem($value);
}

// Gets font awesome styles
//
// .thing-with-icon {
//      @include font-awesome('f054', fal);
// }

@mixin font-awesome($icon, $type) {
    $withslash: "\"\\#{$icon}\"";
    @extend #{$type};
    content: unquote($withslash);
}