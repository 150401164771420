/*----------------------------------*\
  # GLOBAL
\*----------------------------------*/

// Project-level settings and setup.

// Grid
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

// Base typographical styles and baseline grid.
$global-font-size: 62.5% !default; // 10px
$global-line-height: 1.4 !default;

// Standardise some UI treatments.
$global-radius: 1rem !default;
$global-transition: .333333333s !default;

$sm-max-width: 600px;
